<template>
  <div>
    <el-dialog title="新增考点组卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width=80px>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="指定年级"
                          prop="given_garde">
              <el-select v-model="form.given_garde"
                         clearable
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="指定班级"
                          prop="given_class">
              <el-select v-model="form.given_class"
                         clearable
                         filterable
                         @change="classChange"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name "
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷科目"
                          prop="subjectId">
              <!-- getKnowledgeList -->
              <el-select v-model="form.subjectId"
                         style="width:100%"
                         @change="subjectChage"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷地区"
                          prop="district_id">
              <el-select v-model="form.district_id"
                         style="width:100%"
                         filterable
                         placeholder="选择">
                <el-option v-for="item in districtList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷年份"
                          prop="question_year">
              <el-select v-model="form.question_year"
                         style="width:100%"
                         filterable
                         placeholder="选择">
                <el-option v-for="item in year"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷来源">
              <el-select v-model="form.source_id"
                         filterable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="考点">
              <div class="zhishidian">
                <el-cascader v-model="form.knowledge_id"
                             ref="cascaderRef"
                             :options="knowledgeList"
                             :props="props"></el-cascader>
              </div>

            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷学期">
              <el-select v-model="form.semester"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in semesterList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷类型">
              <el-select v-model="form.edu_paper_type"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in paperTypeList"
                           :key="item.paper_type_id"
                           :label="item.paper_type_name"
                           :value="item.paper_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="题目数量"
                          prop="question_num">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.question_num" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="题目难度">
              <el-select v-model="form.difficulty"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in difficultyList"
                           :key="item.val"
                           :label="item.text"
                           :value="item.val">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷时长">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_duration" />
            </el-form-item>

          </el-col>

          <el-col :span="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <p>
            说明：
          </p>
          <p>
            1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
          </p>
          <p>
            2.组的试卷必须提交才能提供给学生做题。
          </p>
          <!-- <p>
            3.指定年级和指定班级二选一
          </p> -->
          <!-- <p>
            （注：试卷类型添加“模考统考”，由上到下依次为“单元检测”、“月考联考”、“期中考试”、“期
            末考试”、“模考统考”）
          </p> -->

        </el-row>
        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="toInfo">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      width: '900px',
      form: {},
      // subjectKey: 'subject_id',
      dialogVisible: false,
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      classList: [],
      sourceList: [],
      knowledgeList: [],
      districtList: [],
      difficultyList: [],
      year: [],
      rules: {
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_class: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      semesterList: [
        { id: 0, label: '上学期' },
        { id: 1, label: '下学期' },
        { id: 2, label: '未指定' },
      ],
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
      }
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId
        // form.knowledge_id = form.knowledge_id.length ? Array.from(new Set(form.knowledge_id.join(',').split(',').map(item => Number(item)))) : []
        let ids = []
        if (Array.isArray(form.knowledge_id)) {
          form.knowledge_id.forEach(item => {
            ids.push(item[item.length - 1])
          });
          form.knowledge_id = ids
        }

        this.$http({
          url: '/api/v1/combination/knowledge_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/testingCentre/info?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get',
      }).then(res => {
        this.districtList = Object.values(res.data)
      })
    },
    getKnowledgeList () {
      this.form.knowledge_id = []
      this.$http({
        url: '/api/v1/question/knowledge',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.knowledgeList = res.data.list
      })
    },
    getSourceList () {

      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
      })
    },
    getYearList () {
      this.$http({
        url: '/api/v1/public/year_all',
        method: 'get',
      }).then(res => {
        this.year = res.data
      })
    },
    addRules () {
      let rules = JSON.parse(JSON.stringify(this.rules))
      if (!this.form.given_class && !this.form.given_garde) {
        rules.given_class = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
        rules.given_garde = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      } else if (this.form.given_garde) {
        delete rules.given_class
        rules.given_garde = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      } else if (this.form.given_class) {
        rules.given_class = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
        delete rules.given_garde
      }
      this.$set(this, 'rules', rules)

      this.form.subjectId = ''
      // this.subjectList = []
      // this.getSubjectList()
    },
    // 试卷类型跟着科目  年级  班级动    选择班级的时候 科目显示的value 是special_class_subject_id
    // 选择年级的时候  科目显示 subject_id   科目年级二传一
    // classChange () {
    //   this.form.given_garde = ''
    //   this.addRules()
    //   // this.subjectKey = 'special_class_subject_id'

    //   if (this.form.given_class == '') {
    //     return
    //   }
    // },
    classChange (value) {
      this.paperTypeList = []

      if (this.form.given_class == '') {
        return
      }
      this.addRules()

    },
    gradeChange () {
      this.form.given_class = ''
      // this.subjectKey = 'subject_id'
      this.addRules()
      if (this.form.given_garde == '') return

      this.subjectList = []
      this.getClass()
      // if (this.form.source_id > 3 && this.form.source_paper == 0) return
      this.getSubjectList()
    },
    getClass () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getList () {
      this.getGradeList()
      this.getDifficultyList()
      this.getDirList()
      this.getYearList()
      this.getSourceList()
      this.getClass()
    },

    getSubjectList () {
      console.log(this.form)
      if (!this.form.given_garde && !this.form.given_class) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = res.data
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    subjectChage () {
      this.paperTypeList = []
      this.knowledgeList = []
      this.form.edu_paper_type = ''

      if ((this.form.given_garde == '' || this.form.given_class == '') && this.form.subjectId == '') return
      if (this.form.given_garde) {
        var form = {
          grade_id: this.form.given_garde,
          subject_id: this.form.subjectId
        }
      } else {
        var special_class_subject_id = this.subjectList.find(item => item.subject_id == this.form.subjectId).special_class_subject_id
        var form = {
          special_class_subject_id: special_class_subject_id
        }
      }

      this.getPaperTypeList(form)

      if (this.form.subjectId == '') {
        return
      }
      this.getKnowledgeList()
    },
    getPaperTypeList (form) {
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET',
        params: form
      }).then(res => {
        if (res.data.paper_type_id) {
          this.paperTypeList = [res.data]
        } else {
          this.paperTypeList = Object.values(res.data)
        }
        if (this.paperTypeList.length == 1) {
          this.form.edu_paper_type = this.paperTypeList[0].paper_type_id
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },

    getDifficultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get'
      }).then(res => {
        this.difficultyList = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
</style>